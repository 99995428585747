// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;


export const colorPrimary='#5B2A8C';
// export const APP_URL='http://localhost:3000/';
export const APP_URL='/'; //https://sites.xperting.club
export const sections = [
  { title: 'Inicio', url: '/' },
];

/** function htmlDecode 
 * @description Decodificar un contenido html
 * @param {String} input corresponde a el contenido a decodificar
 * @return corresponde al texto decodificado.
*/
export function htmlDecode(input) {
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}

/** function escapeHTML 
 * @description Decodificar un contenido html
 * @param {String} input corresponde a el contenido a decodificar
 * @return corresponde al texto decodificado
*/
export const escapeHTML = function(str){
  str.replace(/[&<>'"]/g, 
  tag => ({
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      "'": '&#39;',
      '"': '&quot;',
      ':': '&#58;'
    }[tag]));

    return str;
}


/** function getName
 * @description encontrar el nombre en una lista correspondiente a un id especifico.
 * @param {string} id  es el id del elemento a buscar
 * @param {array} values es la lista de elementos donde buscar 
 * @returns {String}
 */

export function getName(id,values){
  if(typeof id === 'undefined'  || !id ) return '';
  if(values.length > 0 && Array.isArray(values)){
    let x=[];
    if(!isNaN(values)) {
        x = values.filter(value => parseInt(value.id)===parseInt(id) );
    } else {
        x = values.filter(value => value.id===id);
    } 
    return x.length ? x[0].nombre : '';
  }
  return '';
}

export function getAnios(){
  const f = new Date();


  let anios = [];
  for(let i = f.getFullYear() ; i >=2014; i--){
    anios.push(i);
  }
  return anios;
}

export const meses=[{id: 1, nombre: 'Enero'}, {id: 2, nombre: 'Febrero'}, {id: 3, nombre: 'Marzo'}, {id: 4, nombre: 'Abril'}, {id: 5, nombre: 'Mayo'},
                    {id: 6, nombre: 'Junio'}, {id: 7, nombre: 'Julio'}, {id: 8, nombre: 'Agosto'}, {id: 9, nombre: 'Septiembre'}, {id: 10, nombre: 'Octubre'}, 
                    {id: 11, nombre: 'Noviembre'}, {id: 12, nombre: 'Diciembre'}]

export const modulos = [  {id:'bandejas', nombre: 'Bandejas' }, 
                          {id: 'otrasbandejas', nombre: 'Otras Bandejas'}, 
                          {id: 'tareas', nombre: 'Opciones de Usuario' }, 
                         
                          {id: 'correspondencia', nombre: 'Correspondencia'}, 
                          {id: 'administracion', nombre: 'Administración'}, 
                          {id: 'otros', nombre: 'Configuraciónes' }
                        ];

/**
 * 
 * @param {string} disponibles  son los modulos que estan autorizados
 * @returns array of objects
 */
 const filtrarModulo=(disponibles)=> modulos.filter(item=> disponibles.includes(item.id));

     /**
     * Selecciona los módulos por grupo  de usuario
     * Super Administrador Administrador Jefe Departamental Asistentes
     */
    export function modulosPredeterminados(permiso){
      switch (permiso){
          case "1" :
          case "2" :
              return modulos;
          case "3" :
          case "4" :
          case "5" :
          case "6" :
          case "8" :  
              return filtrarModulo( ['bandejas','tareas', 'otrasbandejas' ]);
          default:
              return [];
      }
  }

  /** stringAModulos
   * @description
   * @param {permisos} string 
   */
  export function stringAModulos(permisos){
    if(!permisos) return [];
     if(permisos.trim().length === 0) return [];
     let newArray = permisos.trim().split(',');
     return filtrarModulo(newArray);
  }

  export const categorias=[{id: 1, nombre: 'Extemporáneo'}, {id: 2, nombre: 'Normal'}, {id: 3, nombre: 'Urgente'}];

  export  function comprobar_numero_cedula(cedula) {

    if (typeof(cedula) == 'string' && cedula.length == 10 && /^\d+$/.test(cedula)) {
        var digitos = cedula.split('').map(Number);
        var codigo_provincia = digitos[0] * 10 + digitos[1];

        if (codigo_provincia >= 1 && codigo_provincia <= 24 && digitos[2] < 6) {
            var digito_verificador = digitos.pop();

            var digito_calculado = digitos.reduce(function(valorPrevio, valorActual, indice) {
                return valorPrevio - (valorActual * (2 - indice % 2)) % 9 - (valorActual == 9) * 9;
            }, 1000) % 10;

            return (digito_calculado === digito_verificador);
        }
    }
    return false;
}

var moment = require('moment');



function vencido(fecha){
  let f= new Date(fecha);
  let f2= new Date();
  if(f-f2 <= 0){
      return 'Vencido: ';
  }
  return 'Quedan: ';
}

export function calcDiasLaborables(primeraFecha, secondDate){
        // EDIT : uso de startOf
        let day1 = moment(primeraFecha).startOf('day');
        let day2 = moment().startOf('day');
        // EDIT : Inicia en 1
        let adjust = 1;

        if((day1.dayOfYear() === day2.dayOfYear()) && (day1.year() === day2.year())){
            return 0;
        }

        if(day2.isBefore(day1)){
            const temp = day1;
            day1 = day2;
            day2 = temp;
        }

        //Comprueba si la primera cita comienza los fines de semana
        if(day1.day() === 6) { //Sabado
           //Mover fecha al lunes de la próxima semana
            day1.day(8);
        } else if(day1.day() === 0) { //Domingo
            //Mover fecha al lunes de la semana actual
            day1.day(1);
        }

        //Check if second date starts on weekends
        if(day2.day() === 6) { //Saturday
            //Move date to current week friday
            day2.day(5);
        } else if(day2.day() === 0) { //Sunday
            //Move date to previous week friday
            day2.day(-2);
        }

        const day1Week = day1.week();
        let day2Week = day2.week();

        //Check if two dates are in different week of the year
        if(day1Week !== day2Week){
            //Check if second date's year is different from first date's year
            if (day2Week < day1Week){
                day2Week += day1Week;
            }
            //Calcular el valor de ajuste que se restará de la diferencia entre dos fechas
             // agregar en lugar de asignar (+= en lugar de =)
            adjust += -2 * (day2Week - day1Week);
        }
        
        return vencido(primeraFecha)+ ( day2.diff(day1, 'days') + adjust ) +' días';
    }

    export  function getCeros(num){
      if(!num) return '000';
      return num.length === 1 ? '00' : num.length === 2 ? '0' : '';
  }


  export const interpretarAccion=(valor)=>{
      switch (valor) {
        case 'SELECT':
          return 'Consultar' ;
        case 'UPDATE':
          return 'Actualizar' ;
        case 'INSERT':
          return 'Insertar';
        case 'DELETE':
          return 'Borrar';
        default: 
          return 'Consulta';
      }
    }

    export const formatosPermitidos= ".jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf, .eml, .msg, .ppt, .pptx, .zip, .rar, .mgs";

    export function esFormatoPermitido(archivo){
      const f= formatosPermitidos.split(',');
      for(var i=0; i<f.length; i++){
        if(archivo.includes(f[i])){ return true }
      }
      return false;
    }


    export const serie_transitoria={id:539, nombre:"SERIE TRANSITORIA", area: 0 ,padre:0, baselegal:" ESTADO",descripcion:""};