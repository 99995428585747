import { useState, useRef, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { setUserData, setNotifications } from 'store/ActionsUserData';
import MyStorage from 'store/MyStorage';
import { APP_URL } from 'assets/Constantes';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Chip,
    ClickAwayListener,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Stack,
    Typography
} from '@mui/material';
// Switch,
// OutlinedInput,
// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
// import UpgradePlanCard from './UpgradePlanCard';
import User1 from 'assets/images/users/user-round.svg';

// assets
import { IconLogout, IconSettings, IconBell } from '@tabler/icons-react'; //IconUser
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

/*
// <UpgradePlanCard />
*/

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = (props) => {
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const navigate = useNavigate();

    // const [sdm, setSdm] = useState(true);
    // const [value, setValue] = useState('');
    // const [notification, setNotification] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [open, setOpen] = useState(false);
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);
    const handleLogout = async () => {
        //console.log('Logout ',APP_URL);
        props.setUserData([]);
        props.setNotifications([]);
        new MyStorage().rmUserInfo();
        window.open(APP_URL, "_self");
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleOpenNotification=()=>{
        window.open('/notificaciones', "_self");
    }

    const handleListItemClick = (event, index, route = '') => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    //console.log(props.user);
    //console.log(props.notifications)
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.primary.light,
                    backgroundColor: theme.palette.primary.light,
                    padding: '0 10px',
                    position: 'relative',
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.primary.main,
                        background: `${theme.palette.primary.main}!important`,
                        color: theme.palette.primary.light,
                        '& svg': {
                            stroke: theme.palette.primary.light
                        }
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                
                label={<> 
                <HelpOutlineIcon onClick={()=>window.open('http://gdoc.puertobolivar.gob.ec/manual.pdf')} stroke={1.5} size="1.5rem" color={theme.palette.primary.main} sx={{ marginRight: '15px', cursor: 'pointer' }} />
                <IconBell stroke={1.5} size="1.5rem" color={theme.palette.primary.main} onClick={handleOpenNotification} style={{ marginRight: '15px',cursor: 'pointer'}}/>
                {props.notifications?.length> 0?  <div style={{  position: 'absolute', left: '75px', top: '5px', paddingTop: '10px', textAlign: 'center',
                                                                fontSize: '11px', color: '#fff', borderRadius: '10px', width: '20px', height: '20px', backgroundColor: '#eb0014',   }}>
                                                            {props.notifications.length}
                                                            </div> : null }
                <IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} onClick={handleToggle} style={{ cursor: 'pointer'}}/>
                <IconLogout stroke={1.5} size="1.5rem" color={theme.palette.primary.main} style={{marginLeft: '15px', cursor: 'pointer' }}  onClick={handleLogout} /> 
                </>}
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                
                color="primary"
            />
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Box sx={{ p: 2 }}>
                                        <Stack>
                                            <Stack direction="row" spacing={0.5} alignItems="center">
                                                <Avatar
                                                    src={User1}
                                                    sx={{
                                                        ...theme.typography.mediumAvatar,
                                                        margin: '8px 0 8px 8px !important',
                                                        cursor: 'pointer'
                                                    }}
                                                   
                                                    aria-controls={open ? 'menu-list-grow' : undefined}
                                                    aria-haspopup="true"
                                                    color="inherit"
                                                />
                                                <Stack>
                                                    <Stack direction="row" >
                                                        <Typography variant="h4">Saludos, </Typography>
                                                        <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                                                            { props.user.nombre }
                                                        </Typography>
                                                        
                                                    </Stack>
                                                    <i>{props.user.cargo}</i>
                                                    
                                                </Stack>
                                                
                                               
                                            </Stack>
                                            <Typography variant="subtitle2">  { props.user.area }</Typography>
                                                    <Typography variant="subtitle2"> SP: { props.user.subseccion }</Typography>
                                            
                                        </Stack>

                                    </Box>
                                    <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}>
                                        <Box sx={{ p: 2 }}>


                                            <Divider />
                                            <List
                                                component="nav"
                                                sx={{
                                                    width: '100%',
                                                    maxWidth: 350,
                                                    minWidth: 300,
                                                    backgroundColor: theme.palette.background.paper,
                                                    borderRadius: '10px',
                                                    [theme.breakpoints.down('md')]: {
                                                        minWidth: '100%'
                                                    },
                                                    '& .MuiListItemButton-root': {
                                                        mt: 0.5
                                                    }
                                                }}
                                            >
                                                <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={selectedIndex === 0}
                                                    onClick={(event) => handleListItemClick(event, 0, '/usuario/perfil')}
                                                >
                                                    <ListItemIcon>
                                                        <IconSettings stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">Tus datos de usuario</Typography>} />
                                                </ListItemButton>

                                                <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={selectedIndex === 4}
                                                    onClick={handleLogout}
                                                >
                                                    <ListItemIcon>
                                                        <IconLogout stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">Cerrar Sesión</Typography>} />
                                                </ListItemButton>
                                            </List>
                                        </Box>
                                    </PerfectScrollbar>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

//export default ProfileSection;

const mapStateToProps = (state) => {
    return {
        user: state.user_data.user,
        notifications: state.user_data.notifications
    }
}

const actions = {
  setUserData, setNotifications
}

export default connect(mapStateToProps, actions)(ProfileSection);

/*
<OutlinedInput
    sx={{ width: '100%', pr: 1, pl: 2, my: 2 }}
    id="input-search-profile"
    value={value}
    onChange={(e) => setValue(e.target.value)}
    placeholder="Search profile options"
    startAdornment={
        <InputAdornment position="start">
            <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
        </InputAdornment>
    }
    aria-describedby="search-helper-text"
    inputProps={{
        'aria-label': 'weight'
    }}
/>
<Divider />
*/
/*
<Divider />
<Card
    sx={{
        bgcolor: theme.palette.primary.light,
        my: 2
    }}
>
    <CardContent>
        <Grid container spacing={3} direction="column">
            <Grid item>
                <Grid item container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Typography variant="subtitle1">Start DND Mode</Typography>
                    </Grid>
                    <Grid item>
                        <Switch
                            color="primary"
                            checked={sdm}
                            onChange={(e) => setSdm(e.target.checked)}
                            name="sdm"
                            size="small"
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid item container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Typography variant="subtitle1">Allow Notifications</Typography>
                    </Grid>
                    <Grid item>
                        <Switch
                            checked={notification}
                            onChange={(e) => setNotification(e.target.checked)}
                            name="sdm"
                            size="small"
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </CardContent>
</Card>
*/

/*
<ListItemButton
    sx={{ borderRadius: `${customization.borderRadius}px` }}
    selected={selectedIndex === 1}
    onClick={(event) => handleListItemClick(event, 1, '/user/social-profile/posts')}
>
    <ListItemIcon>
        <IconUser stroke={1.5} size="1.3rem" />
    </ListItemIcon>
    <ListItemText
        primary={
            <Grid container spacing={1} justifyContent="space-between">
                <Grid item>
                    <Typography variant="body2">Social Profile</Typography>
                </Grid>
                <Grid item>
                    <Chip
                        label="02"
                        size="small"
                        sx={{
                            bgcolor: theme.palette.warning.dark,
                            color: theme.palette.background.default
                        }}
                    />
                </Grid>
            </Grid>
        }
    />
</ListItemButton>


    icon={
                    <Avatar
                        src={User1}
                        sx={{
                            ...theme.typography.mediumAvatar,
                            margin: '8px 0 8px 8px !important',
                            cursor: 'pointer'
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color="inherit"
                    />
                }
*/
